import {ref, onMounted, reactive, toRefs} from '@vue/composition-api'
import {getordersituation, setOrderSituationStatus, disableAllSituaction} from "../../api/situation";
import {getPapers} from "../../api/paper";
import {Message} from "element-ui";
export default function useSituations(props,context){
    const dialogState = ref(false)
    const order_situations = ref([])
    const route = context.root.$route;
    const c_order_num = ref('');
    const pageObj = reactive({
        meta: ref({})
    });
    const formState = reactive({
        addForm: reactive({
            deftid_price_num: '',
            paper_id: null,
            use_count: 'one',
            deftid_num: null,
            type_key: null,
        }),
        rules: {
            paper_id: [
                    {required: true, message: '请选择使用系统', trigger: 'change'},
            ],
            deftid_price_num: [
                    {required: true, message: '请输入订单件数', trigger: 'blur'},
            ],
            deftid_num: [
                    {required: true,  message: '请输入生成数量', trigger: 'blur'},
            ],
        }
    })
    const options = ref([])

    const handleCreate = () => {
        dialogState.value = true
    }
    const dialogFormClose = () => {
        dialogState.value = false
        formState.addForm = {
            deftid_price_num: '',
            paper_id: formState.addForm.paper_id = options.value[options.value.length - 1].id,
            use_count: 'one',
            deftid_num: null,
            type_key: null,
        }
    }
    onMounted(async() => {
        getLists()
        const papers = await getPapers()
        options.value = papers.data
        formState.addForm.paper_id = options.value[options.value.length - 1].id
    })
    const handleSwitchPage = (page) => {
        route.query.page = page
        getLists(page)
    }
    const handleFilter = () => {
        getLists()
    }
    const getLists = async (page=1) => {
        const res = await getordersituation({
            page,
            c_order_num: c_order_num.value
        })
        order_situations.value = res.data
        pageObj.meta.value = res.meta
    }
    const ruleForm = ref()
    const handleDisableStatus= async (index, row, status) => {
        console.log(index, row, status)
        const res = await setOrderSituationStatus({
            id: row.id,
            status: status
        })
        if(res.code === 20000) {
            order_situations.value[index].status = res.status
            Message({
                message: '更新成功!',
                type: 'success'
            });
        }
    }
    /* eslint-disable no-use-before-define */
    const handleDisableAll = async () => {
        const res = await disableAllSituaction()
        if(res.code === 20000) {
            Message({
                message: '设置成功!',
                type: 'success'
            });
            getLists(1)
            c_order_num.value = ''
        }
    }
    return {
        dialogState,
        handleFilter,
        dialogFormClose,
        handleCreate,
        meta: pageObj.meta,
        c_order_num,
        handleDisableStatus,
        order_situations,
        handleSwitchPage,
        getLists,
        handleDisableAll,
        options,
        ruleForm,
        ...toRefs(formState)
    }
}