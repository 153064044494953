<template>
    <div class="OrderSituations">
        
      <div class="filter-container" :span="24">
            <template>
                <el-input v-model="situation.c_order_num.value" placeholder="订单号" style="width: 200px;" class="filter-item"
                          @keyup.enter.native="situation.handleFilter"><i class="el-icon-error"></i></el-input>
            </template>

            <el-button :loading="loading" class="filter-item" type="primary" icon="el-icon-search"
                       @click="situation.handleFilter">
                搜索
            </el-button>
        </div>
        <el-container>
            <div class="grid-content">
                <div class="btn">
                    <el-button type="primary" icon="el-icon-plus" size="small" @click="situation.handleCreate"> 添加
                    </el-button>
                    <!--<el-button type="danger" icon="el-icon-plus" size="small" @click="situation.handleDisableAll"> 禁用全部-->
                    <!--</el-button>-->
                </div>
                <div class="table">
                    <div class="tables">
                        <el-table
                                :data="situation.order_situations.value"
                                stripe
                                ref="multipleTable"
                                tooltip-effect="dark"
                                :default-sort="{prop: 'id', order: 'descending'}"
                                style="width: 100%">
                            <el-table-column
                                    label="标识"
                                    sortable
                                    width="80">
                                <template slot-scope="scope">
                                    {{scope.$index + 1}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="订单号"
                                    sortable
                                    width="220">
                                <template slot-scope="scope">
                                    {{scope.row.c_order_num}}
                                    <el-button type="button"
                                               size="mini"
                                               v-clipboard:copy="scope.row.c_order_num"
                                               icon="el-icon-document-copy">
                                    </el-button>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="成交金额"
                                    width="80">
                                <template slot-scope="scope">
                                    {{ scope.row.use_price }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="已用/余/总"
                                    align="center"
                                    width="100">
                                <template slot-scope="scope">
                                    {{scope.row.use_deftid_num || 0}}/{{scope.row.deftid_price_num -
                                    scope.row.use_deftid_num}}/{{scope.row.deftid_price_num}}件
                                </template>
                            </el-table-column>
                            <el-table-column label="订单属性">
                                <template slot-scope="scope">
                                    {{scope.row.use_count == 'one' ? '单次使用' : '多次使用'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="订单状态">
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.status == 0 ? 'danger' : 'success'">{{scope.row.status == 0
                                        ? '禁止' : scope.row.status == 1 ? '可用' : '已用'}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="检测系统">
                                <template slot-scope="scope">
                                    {{scope.row.paper.name}}
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.created_at}}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-popconfirm title="确定启用吗？"
                                                   @confirm="situation.handleDisableStatus(scope.$index, scope.row, scope.row.use_count == 'one' ? 1 : 2)"
                                    >
                                        <el-button

                                                slot="reference"
                                                size="mini">
                                            启用
                                        </el-button>
                                    </el-popconfirm>


                                    <!--                                    <el-button-->
                                    <!--                                            size="mini"-->
                                    <!--                                            type="danger"-->
                                    <!--                                            @click.native.prevent="handleDerc(scope.$index, scope.row)"-->
                                    <!--                                            slot="reference">消耗</el-button>-->
                                    <el-popconfirm
                                            title="确定禁用吗？"
                                            @confirm="situation.handleDisableStatus(scope.$index, scope.row, 0)"
                                    >
                                        <el-button
                                                size="mini"
                                                :disabled="scope.row.status == 0"
                                                type="danger"
                                                slot="reference">禁用
                                        </el-button>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="block">
                        <pagination
                                :hiddenSingle="false"
                                :meta="situation.meta.value"
                                :layout="'prev, pager, next'"
                                v-on:pagination:switched="situation.handleSwitchPage"
                        />
                    </div>
                </div>
            </div>
        </el-container>
        <el-dialog title="自定义订单添加"
                   :close-on-click-modal="false"
                   :before-close="situation.dialogFormClose"
                   :visible.sync="situation.dialogState.value">
            <el-form :model="situation.addForm.value" :rules="situation.rules.value" ref="ruleForm">
                <el-row :gutter="20">
                    <el-col :span="24" style="display:flex;width: 100%;flex-direction: column">
                        <el-form-item label="订单属性:" prop="use_count">
                            <el-radio label="one" v-model="situation.addForm.value.use_count" value="one"> 单次使用
                            </el-radio>
                            <el-radio label="multi" v-model="situation.addForm.value.use_count" value="multi"> 多次使用
                            </el-radio>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="自助检测类别" v-if="situation.options['value']" prop="paper_id"
                                      style="margin-bottom: 0">
                            <el-select v-model="situation.addForm.value.paper_id" placeholder="请选择"
                                       style="width: 100%">
                                <el-option
                                        v-for="item in situation.options['value']"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="订单件数" prop="deftid_price_num">
                            <el-input v-model="situation.addForm.value.deftid_price_num"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="生成数量" prop="deftid_num">
                            <el-input v-model="situation.addForm.value.deftid_num"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="批次名称" prop="type_key">
                            <el-input v-model="situation.addForm.value.type_key"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">添加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="form-actions">
                <p>
                    <span class="label label-info">订单件数?</span> <span class="muted">必填项，表示一个订单能测多少件商品</span>
                </p>
                <p>
                    <span class="label label-info">生成数量?</span> <span class="muted">必填项，表示一次性要生成多少个这样的订单</span>
                </p>
                <p>
                    <span class="label label-info">单次使用?</span> <span class="muted">表示只能使用一次，不管订单还剩多少件，第二次均无法使用</span>
                </p>
                <p>
                    <span class="label label-info">多次使用?</span> <span
                        class="muted">表示可以多次使用，比如10件的订单，第一次使用4件，第二次使用6件</span>
                </p>
                <p>
                    <span class="label label-info">批次名称?</span> <span class="muted">表示当前批次的名称，可以为空，该功能主要用于搜索与筛选使用</span>
                </p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Pagination from "../../components/pagination/Pagination";
    import useSituations from '@/composables/order/use-situations';
    import {ref} from '@vue/composition-api'
    import {addOrderSituaction, startOrder} from "../../api/situation";
    import {Message} from 'element-ui'

    export default {
        name: "OrderSituations",
        components: {
            Pagination
        },
        methods: {
            async startOrder() {

            },
            async handleStart(index, row) {
                this.$confirm('确定要启用吗?').then(async () => {
                    const rsp = await startOrder({
                        id: row.id
                    })
                    console.log('rsp', rsp)
                    if (rsp && rsp.code === 20000) {
                        row.status = 0;
                        // situation.getLists();
                        Message.success(rsp.msg);
                    }
                })
            }
        },
        setup(props, context) {
            const situation = useSituations(props, context)
            const ruleForm = ref(null);
            const onSubmit = () => {
                // eslint-disable-next-line no-unreachable
                console.log('onSubmit');
                ruleForm.value.validate(async (valid) => {

                    if (valid) {
                        if (confirm('生成订单时,将不会扣除您的余额，只有在订单使用时才扣除检测时实际的余额,是否继续操作')) {

                            const rsp = await addOrderSituaction(situation.addForm.value)
                            console.log('rsp', rsp)
                            if (rsp && rsp.code === 20000) {
                                situation.addForm.value = {
                                    deftid_price_num: '',
                                    paper_id: null,
                                    use_count: 'one',
                                    deftid_num: null,
                                    type_key: null,
                                }
                                situation.dialogFormClose()
                                situation.getLists();
                                Message.success(rsp.msg);
                            }
                        }
                    } else {

                        return false;
                    }
                });
            }
            return {
                situation,
                onSubmit,
                ruleForm
            }
        },
    }
</script>

<style scoped>
    .grid-content {
        float: right;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 100%;
    }

    .btn {
        float: right;
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
    }

    >>> .el-form-item__content {
        display: flex;
        width: 100%;
    }
</style>