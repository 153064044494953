import { get, post } from "../utils/http";

export function getordersituation(params) {
    return get({
        url: 'admin/order-situation?admin=true',
        method: 'get',
        params: params
    })
}

export function setOrderSituationStatus(params) {
    return post({
        url: 'admin/order-situation/status?admin=true',
        method: 'post',
        params: params
    })
}

export function addOrderSituaction(params) {
    return post({
        url: 'admin/order-situation?admin=true',
        method: 'post',
        params: params
    })
}
export function startOrder(params) {
    return post({
        url: 'admin/start-order-situation?admin=true',
        method: 'post',
        params: params
    })
}
export function disableAllSituaction(params) {
    return post({
        url: 'admin/start-order-situation/disableAllSituaction?admin=true',
        method: 'post',
        params: params
    })
}