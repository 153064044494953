import { render, staticRenderFns } from "./OrderSituations.vue?vue&type=template&id=27a8c0b8&scoped=true&"
import script from "./OrderSituations.vue?vue&type=script&lang=js&"
export * from "./OrderSituations.vue?vue&type=script&lang=js&"
import style0 from "./OrderSituations.vue?vue&type=style&index=0&id=27a8c0b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a8c0b8",
  null
  
)

export default component.exports